@import '~antd/dist/antd.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
  background-color: #F5F8FC;
  font-family: "Quicksand", 'sans-serif';
}

body {
  margin: 0;
  font-family: "Quicksand", 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #5b626b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Editor JS*/
.codex-editor__redactor {
  padding: 0!important;
}

.ce-block__content {
  max-width: calc(100% - 8rem) !important;
}

.ce-toolbar__content {
  max-width: calc(100% - 8rem)!important;
  left: 0!important;
}

.ce-toolbar__plus {
  /*left: 0!important;*/
}


/* antd override */

/* FORM */
.ant-form-item {
  margin-bottom: 0;
}

.ant-form label {
  user-select: none;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #555;
}

/* CARD */
.ant-card {
  border-radius: 1rem;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

/* LAYOUT */
.ant-layout-content {
  max-width: 1260px;
  width: 100%;
  margin: auto;
}

/* TABLE */
tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

h1 {
  font-family: "Quicksand", 'sans-serif';
  font-weight: 700;
  font-size: 2.4rem;
  color: #3E475E;
}

p {
  font-family: "Quicksand", 'sans-serif';
  font-weight: 500;
  font-size: 1.4rem;
  color: #8198AA;
}

tr {
  font-size: 1.6rem;
  font-weight: 500;
}
